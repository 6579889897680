import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Route,Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { StorageService } from '../services/storage.service';
import { AuthConstants } from '../constants/auth-constants';
@Injectable({
  providedIn: 'root'
})
export class RoleGuard {
  constructor(
    private authService : AuthService,
    private route: ActivatedRoute,
    private router : Router,
    public storageService : StorageService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return new  Promise((resolve,reject) =>{
      this.storageService.get(AuthConstants.AUTH).then(res =>{
        const roles = route.data['roles'] as Array<string>;
        if(res && roles.includes(res.role)){
          resolve(true)
        }else {
          this.router.navigate(['error/400'])
          reject(true)
        }
      }).catch(error =>{
        resolve(false)
      })
    })
  }
}

