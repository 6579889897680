import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContextMenuService {
  private showContextMenuSubject = new BehaviorSubject<boolean>(false);
  showContextMenu$: Observable<boolean> = this.showContextMenuSubject.asObservable();

  private contextMenuPositionSubject = new BehaviorSubject<{ x: number; y: number }>({ x: 0, y: 0 });
  contextMenuPosition$: Observable<{ x: number; y: number }> = this.contextMenuPositionSubject.asObservable();

  private contextMenuDataSubject = new BehaviorSubject<any>(null);
  contextMenuData$: Observable<any> = this.contextMenuDataSubject.asObservable();

  private editButtonClickSubject = new BehaviorSubject<void>(undefined);
  editButtonClick$: Observable<void> = this.editButtonClickSubject.asObservable();

  openContextMenu(position: { x: number; y: number }, data: any): void {
    this.contextMenuPositionSubject.next(position);
    this.contextMenuDataSubject.next(data);
    this.showContextMenuSubject.next(true);
  }

  closeContextMenu(): void {
    this.showContextMenuSubject.next(false);
  }

  getMenuData(): any {
    return this.contextMenuDataSubject.getValue();
  }

  editButtonClick(): void {    
    this.editButtonClickSubject.next();
  }
}
