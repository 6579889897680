import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { map, filter, scan } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  async store(storagekey: string, value:any) {
    const encryptedValue = btoa(encodeURIComponent(JSON.stringify(value)));
    localStorage.setItem(
      storagekey,
      encryptedValue)
  }

  async get(storagekey){
    const res = localStorage.getItem(storagekey);
    if(res){
      return JSON.parse(decodeURIComponent(atob(res)))
    }else {
      return false
    }
  }

  async removeItem(storagekey) {
     localStorage.removeItem(storagekey);
  }

}

