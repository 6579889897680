import { Component, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { ContextMenuService } from '../../services/context-menu.service';

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss']
})

export class ContextMenuComponent implements OnInit {
  menuData: any;
  showContextMenu: boolean = false;
  contextMenuPosition: { x: number; y: number } = { x: 0, y: 0 };
  private documentClickListener: () => void;

  constructor(
    private contextMenuService: ContextMenuService,
    private renderer: Renderer2,
    private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.contextMenuService.contextMenuData$.subscribe(data => {
      this.menuData = data;
    });

    this.contextMenuService.showContextMenu$.subscribe(show => {
      this.showContextMenu = show;
    });

    this.contextMenuService.contextMenuPosition$.subscribe(position => {
      this.contextMenuPosition = position;
    });
  }

  @HostListener('document:contextmenu', ['$event'])
  onRightClick(event: MouseEvent): void {
    event.preventDefault();
    this.contextMenuService.openContextMenu({ x: event.clientX-250, y: event.clientY-30 }, this.menuData);
  }


  onEditClick(): void {
    this.contextMenuService.closeContextMenu();
    this.contextMenuService.editButtonClick();
  }

   closeContextMenu(): void {
    this.contextMenuService.closeContextMenu();
  }
}

