<!-- footer start-->
<div class="container-fluid">
  <div class="row">
    <div class="col-md-6 footer-copyright">
      <p class="mb-0">© STULZ CHSPL INDIA PVT LTD</p>
    </div>

    <!-- <div class="col-md-6">
      <p class="pull-right mb-0">Hand crafted by Monu Kumar<i class="fa fa-heart"></i></p>
    </div> -->
  </div>
</div>
