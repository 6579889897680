import { NgModule } from "@angular/core";
import { RouterModule, Routes, PreloadAllModules } from "@angular/router";
import { ContentLayoutComponent } from "./shared/components/layout/content-layout/content-layout.component";
import { FullLayoutComponent } from "./shared/components/layout/full-layout/full-layout.component";
import { content } from "./routes/content-routes";
import { full } from "./routes/full.routes";
import { A } from "@angular/cdk/keycodes";
// import { AdminGuard } from "./shared/guards/admin.guard";
import { AuthGuard } from "./shared/guards/auth.guard";
import { RoleGuard } from "./shared/guards/role.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "auth",
    pathMatch: "full",
  },
  {
    path: "app",
    component: ContentLayoutComponent,
    children: content,
  },
  {
    path: "auth",
    // component: FullLayoutComponent,
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'coming-soon',
    loadChildren: () => import('./pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule),
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./pages/maintenance/maintenance.module').then(m => m.MaintenanceModule),
  },
  {
    path: 'error',
    loadChildren: () => import('./pages/error-pages/error-pages.module').then(m => m.ErrorPagesModule),
  },
  // {
  //   path: "**",
  //   redirectTo: "/error/400"
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      anchorScrolling: "enabled",
      scrollPositionRestoration: "enabled",
    }),
  ],
  exports: [RouterModule],
  providers:[
    // AdminGuard,
    AuthGuard,
    RoleGuard
  ]
})
export class AppRoutingModule {}
