import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NavService, Menu } from '../../services/nav.service';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {

  public menuItems: Menu[] | any;
  public url: any;
  public fileurl: any;
  user: any;

  constructor(
    private router: Router, 
    public navServices: NavService,
    private storageService: StorageService,
    ) { }
    async ngOnInit() {
      this.user = await this.storageService.get('user');
      await this.getUserData();      
    //   this.navServices.vendorItems.subscribe(menuItems => {
    //     this.getUserData()
    //   this.menuItems = menuItems
    //   this.router.events.subscribe((event) => {
    //     if (event instanceof NavigationEnd) {
    //       this.menuItems.filter(items => {
    //         if (items.path === event.url)
    //           this.setNavActive(items)
    //         if (!items.children) return false
    //         items.children.filter(subItems => {
    //           if (subItems.path === event.url)
    //             this.setNavActive(subItems)
    //           if (!subItems.children) return false
    //           subItems.children.filter(subSubItems => {
    //             if (subSubItems.path === event.url)
    //               this.setNavActive(subSubItems)
    //           })
    //           return
    //         })
    //         return
    //       }
    //       )
    //     }
    //   })
    // })
    }

  // Active Nave state
  setNavActive(item:any) {
    this.menuItems.filter((menuItem:any) => {
      if (menuItem != item)
        menuItem.active = false
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true
      if (menuItem.children) {
        menuItem.children.filter((submenuItems:any) => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true
            submenuItems.active = true
          }
        })
      }
    })
  }

  //get user data
  async getUserData(){
    // this.storageService.get('user').then((user) => {
      switch (this.user.role) {
        case 'vendor':
          this.fileurl = 'assets/images/vendor.jpg';
          this.menuItems =this.navServices.vendorItems;
          break;
        case 'accountant':
          this.fileurl = 'assets/images/accountant.jpg';
          this.menuItems = this.navServices.accountantItems;
          break;
        case 'admin':
          this.fileurl = 'assets/images/admin.jpg';
          // this.menuItems = this.navServices.adminItems;
          break;
      }
      this.menuItems.subscribe(menuItems => {
        this.menuItems = menuItems
        this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            this.menuItems.filter(items => {
              if (items.path === event.url)
                this.setNavActive(items)
              if (!items.children) return false
              items.children.filter(subItems => {
                if (subItems.path === event.url)
                  this.setNavActive(subItems)
                if (!subItems.children) return false
                subItems.children.filter(subSubItems => {
                  if (subSubItems.path === event.url)
                    this.setNavActive(subSubItems)
                })
                return
              })
              return
            }
            )
}})
      })
      
}

  // Click Toggle menu
  toggletNavActive(item:any) {
    if (!item.active) {
      this.menuItems.forEach((a:any) => {
        if (this.menuItems.includes(item))
          a.active = false
        if (!a.children) return false
        a.children.forEach((b:any) => {
          if (a.children.includes(item)) {
            b.active = false
          }
        })
        return
      });
    }
    item.active = !item.active
  }

  //Fileupload
  readUrl(event: any) {
    if (event.target.files.length === 0)
      return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url = reader.result;
    }
  }

  

}
