import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthConstants } from '../constants/auth-constants';
import { HttpService } from './http.service';
import { StorageService } from './storage.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

userData$ = new BehaviorSubject<any>('')
  constructor(
    private httpService : HttpService,
    private storageService: StorageService,
    private router : Router
    ) { }
    async getUserData() {
      await this.storageService.get(AuthConstants.AUTH).then(res =>{
        this.userData$.next(res);
      })
    }
    getVendorRegistrationTokenData(postData:object): Observable <any> {
      return this.httpService.post('api/auth/vendorRegTokenInfo',postData,"")
    }

    isLoggedIn(): boolean {      
      return true;
    }  

    hasRole(role: string): boolean {
      console.log(role);
      
      return true; 
    }
    async getToken() {
      let userData=await this.storageService.get(AuthConstants.AUTH)      
      return userData.accessToken;
    }
    async refreshToken() {
      let userData=await this.storageService.get(AuthConstants.AUTH)      
      return userData.accessToken;
    }
    accountantLogin(postData:object): Observable<any> {
      return this.httpService.post('api/auth/accountsSignIn',postData,"")
    }
    vendorLogin(postData:object): Observable <any> {
      return this.httpService.post('api/auth/vendorSignIn',postData,"")
    }
    registerVendor(postData:object): Observable <any> {
      return this.httpService.post('api/auth/vendorSignUp',postData,"")
    }

    async logout(){
      let userData=await this.storageService.get(AuthConstants.AUTH)      
      const res = await this.storageService.removeItem(AuthConstants.AUTH);
      let rootUrl ;
      switch (userData.role) {
        case 'vendor':
          rootUrl = 'auth/vendor/login';
          break;
        case 'accountant':
          rootUrl = 'auth/accountant/login';
          break;    
        default:
          rootUrl = 'auth/accountant/login';
          // rootUrl = 'error/400';
          break;
      }
      
      this.router.navigate([rootUrl]);
      this.userData$.next('');
    }

    forgotPassword(postData): Observable<any> {
      return this.httpService.post('api/user/forgotPassword',postData,"")
    }
    resetPassword(requestType:any,url:String,postData:Object): Observable<any> {
      return this.httpService[requestType](url,postData,'')
    }

}
