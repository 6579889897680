import { Routes } from '@angular/router';
import { AutoLoginService } from '../shared/services/auto-login.service';
import { RoleGuard } from '../shared/guards/role.guard';

export const content: Routes = [
  {
    path: '',
    redirectTo: 'vendor',
    pathMatch: "full"
  },
  {
    path: 'accountant',
    loadChildren: () => import('../modules/accountant/accountant.module').then(m => m.AccountantModule),
    canActivate: [RoleGuard],
    data: { roles: ['accountant','admin'] }
  },
  {
    path: 'vendor',
    loadChildren: () => import('../modules/vendor/vendor.module').then(m => m.VendorModule),
    canActivate: [RoleGuard],
    data: { roles: ['vendor','admin'] }
  },
];
