// import { NgModule } from "@angular/core";
// import { CommonModule } from "@angular/common";
// import { RouterModule } from "@angular/router";
// import { FormsModule, ReactiveFormsModule } from "@angular/forms";
// import { LoaderComponent } from "./components/loader/loader.component";
// import { HeaderComponent } from "./components/header/header.component";
// import { ContextMenuComponent } from "./components/context-menu/context-menu.component";
// import { FooterComponent } from "./components/footer/footer.component";
// import { SidebarComponent } from "./components/sidebar/sidebar.component";
// import { ContentLayoutComponent } from "./components/layout/content-layout/content-layout.component";
// import { FullLayoutComponent } from "./components/layout/full-layout/full-layout.component";
// import { FeatherIconsComponent } from "./components/feather-icons/feather-icons.component";
// import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
// import { BookmarkComponent } from "./components/bookmark/bookmark.component";
// import { TranslateModule } from "@ngx-translate/core";
// import { NgbModule, NgbNavModule, NgbModalModule } from "@ng-bootstrap/ng-bootstrap";
// // import { BrowserModule } from "@angular/platform-browser";
// import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
// import { ChartistModule } from 'ng-chartist';
// import { CarouselModule } from 'ngx-owl-carousel-o';
// import { NgChartsModule } from "ng2-charts";
// import { NgxChartsModule } from "@swimlane/ngx-charts";
// import { Ng2GoogleChartsModule } from "ng2-google-charts";
// import { NgApexchartsModule } from "ng-apexcharts";
// import { NgxDatatableModule } from '@swimlane/ngx-datatable';
// import { NgxDropzoneModule } from 'ngx-dropzone';




// // services
// import { NavService } from "./services/nav.service";
// import { CustomizerService } from "./services/customizer.service";
// // Directives
// import { HttpClient, HttpClientModule } from "@angular/common/http";
// import { TranslateHttpLoader } from "@ngx-translate/http-loader";
// import { F } from "@angular/cdk/keycodes";
// import { AutoLoginService } from "./services/auto-login.service";
// import { StorageService } from "./services/storage.service";
// import { AuthService } from "./services/auth.service";
// import { TranslationService } from "./services/translate.service";


// export function HttpLoaderFactory(http: HttpClient) {
//   return new TranslateHttpLoader(http);
// }

// @NgModule({
//   declarations: [LoaderComponent, HeaderComponent, ContextMenuComponent, FooterComponent, SidebarComponent, BookmarkComponent, ContentLayoutComponent, FullLayoutComponent, FeatherIconsComponent, BreadcrumbComponent],
//   imports: [
//     CommonModule, 
//     // BrowserModule, 
//     RouterModule, 
//     FormsModule,
//      TranslateModule, 
//      NgbModule, 
//      ReactiveFormsModule, 
//     //  BrowserAnimationsModule, 
//      HttpClientModule, ChartistModule,
//     CarouselModule, NgChartsModule, NgxChartsModule, Ng2GoogleChartsModule, NgbNavModule, NgbModalModule, NgApexchartsModule,NgxDropzoneModule],
//   exports: [LoaderComponent, FeatherIconsComponent, TranslateModule, HeaderComponent, ContextMenuComponent, FooterComponent, SidebarComponent, BreadcrumbComponent, ChartistModule],
//   providers: [NavService, CustomizerService, AuthService, AutoLoginService, StorageService, TranslationService],
// })
// export class SharedModule { }


import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { LoaderComponent } from "./components/loader/loader.component";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { ContentLayoutComponent } from "./components/layout/content-layout/content-layout.component";
import { FullLayoutComponent } from "./components/layout/full-layout/full-layout.component";
import { FeatherIconsComponent } from "./components/feather-icons/feather-icons.component";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { BookmarkComponent } from "./components/bookmark/bookmark.component";
import { TranslateModule } from "@ngx-translate/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap"; 
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ChartistModule } from 'ng-chartist';


// services
import { NavService } from "./services/nav.service";
import { CustomizerService } from "./services/customizer.service";
// Directives
import { HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ContextMenuComponent } from "./components/context-menu/context-menu.component";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    LoaderComponent, 
    HeaderComponent, 
    FooterComponent, 
    SidebarComponent, 
    BookmarkComponent,
    ContentLayoutComponent, 
    FullLayoutComponent, 
    FeatherIconsComponent, 
    BreadcrumbComponent,
    ContextMenuComponent
  ],
  imports: [
    CommonModule, 
    RouterModule, 
    FormsModule, 
    TranslateModule, 
    NgbModule,    
    NgxDropzoneModule,
    ChartistModule
  ],
  exports: [LoaderComponent, FeatherIconsComponent,ContextMenuComponent, TranslateModule],
  providers: [NavService, CustomizerService], 
})
export class SharedModule {}

