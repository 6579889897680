// import { CanActivateFn } from '@angular/router';
// import { Injectable } from '@angular/core';
// import { Observable } from 'rxjs';
// import { AuthConstants } from '../constants/auth-constants';
// import { AuthService } from '../shared/services/auth.service';
// import { StorageService } from '../shared/services/storage.service';

// // @Injectable({
// //   providedIn: 'root'
// // })

// export const authGuard: CanActivateFn = (route, state) => {

//   return true;
// };
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthGuard  {
  constructor(public router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const res:any =  localStorage.getItem('user');
    let user = JSON.parse(decodeURIComponent(atob(res))) || {};
    if (!user || user === null) {
      this.router.navigate(['/auth/vendor/login']);
      return true;
    }
    else if (user) {
      if (!Object.keys(user).length) {
        this.router.navigate(['/auth/vendor/login']);
        return true;
      }
    }
    return true;
  }
}



