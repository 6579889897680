import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  constructor(private http : HttpClient
    ) { }

  post(serviceName: string, data:any,token:any): Observable<any>{
    const headers = new HttpHeaders().set('Authorization',token)
    const options = {headers}        
    const prodUrl = environment.apiUrl + serviceName    
    return this.http.post(prodUrl,data,options);
  }


  // delete(serviceName: string, data:any,token:any): Observable<any>{
  //   const headers = new HttpHeaders().set('Authorization',token)
  //   const options = {headers,body:data}
  //   const url = environment.apiUrl + serviceName
  //   return this.http.delete(url,options);
  // }
}
