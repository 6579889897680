import { HttpErrorResponse, HttpHeaders, HttpInterceptorFn } from '@angular/common/http';

// export const authInterceptor: HttpInterceptorFn = (req, next) => {
//   return next(req);
// };

// import { Injectable } from '@angular/core';
// import {
//   HttpEvent,
//   HttpInterceptor,
//   HttpRequest,
//   HttpHandler,
//   HttpResponse,
//   HttpErrorResponse,
// } from '@angular/common/http';
// import { Observable, throwError } from 'rxjs';
// import { catchError } from 'rxjs/operators';
// import { AuthService } from '../services/auth.service';
// import { Router } from '@angular/router';


// @Injectable()
// export class AuthInterceptor implements HttpInterceptor {
//   constructor(
//     private authService: AuthService,
//     private router:Router
//     ) {}

//   intercept(
//     req: HttpRequest<any>,
//     next: HttpHandler
//   ): Observable<HttpEvent<any>> {
//     if (req.url.includes('/api/auth/accountsSignIn') || req.url.includes('/api/auth/vendorSignIn')) {
//           return next.handle(req);
//     }

//     this.authService.getToken().then((authToken)=>{
//             if (authToken) {        
//               req = req.clone({
//                 setHeaders: {
//                   Authorization: `Bearer ${authToken}`
//                 }
//               });
//             }})         
//     return next.handle(req)
  //   .pipe(
  //       catchError((error) => {
  //     if (error instanceof HttpErrorResponse) {
  //       if (error.error instanceof ErrorEvent) {
  //           console.error("Error Event");
  //       } else {
  //           switch (error.status) {
  //               case 401:      //login
  //               // this.authService.logout()
  //               case 403:     //forbidden
  //                   this.authService.logout()
  //                   this.router.navigateByUrl("authentication/accountant/login");
  //                   break;
  //           }
  //       } 
  //   } else {
  //     console.log("Server Error", error);
      
  //       console.error("some thing else happened");
  //   }
  //   return throwError(() => new Error(error));
                
  //   })
  // )
  // }

  //   if (req.url.includes('/api/auth/accountsSignIn') || req.url.includes('/api/auth/vendorSignIn')) {
  //     return next.handle(req);
  //   }
  //   else{
      
  //     this.authService.getToken().then((authToken)=>{
  //       if (authToken) {        
  //         req = req.clone({
  //           setHeaders: {
  //             Authorization: `Bearer ${authToken}`
  //           }
  //         });
  //         return next.handle(req).pipe(
  //           catchError((error: HttpErrorResponse) => {
  //             if (error.status === 401) {
  //               // Handle unauthorized error (redirect to login)
  //               console.error('Unauthorized request');
  //               return throwError(() => new Error('Unauthorized'));
  //             } else {
  //               return throwError(() => new Error('Something went wrong'));
  //             }
  //           })
  //         );
  //       }
  //     else {
  //       // Handle missing token (e.g., redirect to login)
  //       console.error('No authorization token');
  //       return throwError(() => new Error('Unauthorized')); // Correct usage
  //     }
        
  //     })
  //   }
  // }
// }


import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpResponse,
} from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { Observable, throwError,from, lastValueFrom } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router:Router
    ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.handle(req, next))
  }
  async handle(req: HttpRequest<any>, next: HttpHandler) {
    if (
      req.url.includes('/api/auth/accountsSignIn') || 
      req.url.includes('/api/auth/vendorSignIn')
      ) {
        return await lastValueFrom(next.handle(req));
    }
    return await lastValueFrom (next.handle(req)
        .pipe(
            catchError((error) => {
          if (error instanceof HttpErrorResponse) {
            if (error.error instanceof ErrorEvent) {
                console.error("Error Event");
            } else {
                switch (error.status) {
                    case 401:    
                    this.authService.logout()
                    break
                    case 403:     //forbidden
                        this.router.navigateByUrl("/login");
                        break;
                }
            } 
        } else {
            console.error("some thing else happened");
        }
        return throwError(() => new Error(error))
                    
        })
      ))
    
    return await lastValueFrom(next.handle(req));
  }
}
